import React from 'react'
import Layout from '../../components/layout'
import Header from '../../components/Header'
import { Helmet } from 'react-helmet'
import Sidebar from '../../components/Sidebar/Sidebar'

const VandensParuosimas = () => (
    <Layout>
      <Helmet>
          <title>Reagentai Vandens Apdirbimui</title>
      </Helmet>
      <Header
        styleName='header header-water'
        title='Vandens apdirbimas'
        />
      <div className="columns">
        <div className="column content">
          <div className="title-small-nomargintop title-small content-title">Reagentai vandens apdirbimui</div>
          <div className="content-text"> UAB "Inžinerinė ekologija" specialistai, remdamiesi sistemos tyrimais bei vandens kokybės parametrų analizės duomenimis, kiekvienam klientui parinks individualią vandens apdirbimo reagentais programą.</div>
          <div className="title-small content-title">Reagentai garo katilams ir šildymo sistemoms</div>
          <div className="content-text">Reagentai IN-ECO stabdo korozijos procesus, apsaugo nuo nuovirų susidarymo, reguliuoja pH reikšmę, sudaro sąlygas patvaraus pasyvuojančio sluoksnio (magnetito) susidarymui. Tai užtikrina geresnį šilumos perdavimą,  garo kokybę, sumažina kuro sąnaudas ir įrengimų aptarnavimo išlaidas.</div>
          <table className="table content-table">
            <tbody>
            <tr>
              <td>Korozijos ir nuovirų inhibitoriai katilams</td>
              <td>IN-ECO 301; 307; 308; 315</td>
            </tr>
            <tr>
              <td>Deguonies surišėjai</td>
              <td>IN-ECO 324-2; 326</td>
            </tr>
            <tr>
              <td> Korozijos inhibitoriai garo-kondensato linijoms</td>
              <td>IN-ECO 332; 334; 336; 3263</td>
            </tr>
            <tr>
              <td>Šildymo sistemoms (termofikacijos procesams)</td>
              <td>IN-ECO 301; 390; 392; 3553</td>
            </tr>
            <tr>
              <td>Antiputokšliai</td>
              <td>IN-ECO 302-31</td>
            </tr>
            </tbody>
          </table>
          <div className="title-small content-title"> Reagentai cirkuliacinėms aušinimo sistemoms</div>
          <div className="content-text">Reagentai IN-ECO užtikrina apsaugą nuo mineralinių ir organinių nuosėdų susidarymo, stabdo korozijos  procesus, pasyvuoja įvairių tipų metalus. Tai sudaro galimybę palaikyti aušinimo sistemose didesnį koncentravimą, ilgą laiką išlaikyti efektyvius šilumos mainus.</div>
          <table className="table content-table">
            <tbody>
            <tr>
              <td>Korozijos ir nuovirų inhibitoriai atviroms sistemoms</td>
              <td>IN-ECO 101; 103; 114; 131; 133; 134; 135; 150; 151</td>
            </tr>
            <tr>
              <td>Korozijos ir nuovirų inhibitoriai uždaroms sistemoms</td>
              <td>IN-ECO 160; 161; 170; 174</td>
            </tr>
            <tr>
              <td>Biocidai (neoksiduojantys)</td>
              <td>IN-ECO 264; 267; 268; 2641</td>
            </tr>
            <tr>
              <td>Biocidai (oksiduojantys) </td>
              <td>IN-ECO 210; 510; 511</td>
            </tr>
            <tr>
              <td>Biodispersantai </td>
              <td>IN-ECO 250; 251</td>
            </tr>
            </tbody>
          </table>
          <div className="title-small content-title">Reagentai atbulinio osmoso ir ultrafiltracijos membraninėms technologijoms</div>
          <table className="table content-table">
            <tbody>
            <tr>
              <td>Antiskalantai</td>
              <td>IN-ECO 550; 551; 552; 553; 554</td>
            </tr>
            <tr>
              <td>Plovikliai</td>
              <td>IN-ECO 535; 537; 538; 540; 541</td>
            </tr>
            <tr>
              <td>Konservantai</td>
              <td>IN-ECO 501; 502</td>
            </tr>
            <tr>
              <td>Dezinfekcijai</td>
              <td>IN-ECO 510; 513; 515</td>
            </tr>
            <tr>
              <td>Papildomi reagentai pirminiam apdirbimui ir korekcijai</td>
              <td>IN-ECO 600; 210; 501</td>
            </tr>
            </tbody>
          </table>
          <div className="title-small content-title">Reagentai įrangos aptarnavimui ir šilumokaičių plovimui</div>
          <table className="table content-table">
            <tbody>
            <tr>
              <td>Šilumokaičių ir katilų plovikliai</td>
              <td>IN-ECO 401; 402; 403; 424</td>
            </tr>
            <tr>
              <td>Pasyvacija, pirminis apdorojimas, nuriebalinimas</td>
              <td>IN-ECO 411; 412; 4121</td>
            </tr>
            <tr>
              <td>Koaguliantai ir flokuliantai pirminiam apdorojimui</td>
              <td>IN-ECO 600; 6025; 6015; 882; 850</td>
            </tr>
            </tbody>
          </table>
          <div className="title-small content-title">Dervos ir užpildai vandens paruošimo filtrams</div>
          <div className="content-text">Siūlome pilną spektrą Purolite dervų (katijonitinių, anijonitinių) bei skirtingų užpildų mechaniniams nugeležinimo filtrams.</div>
        </div>
        <Sidebar
        analysis='Anketos'
        boiler='Boileriams'
        cooling='Aušinimui'
        ro='Osmosui'
        broshure='IN-ECO brošiura'
        certificates='Sertifikatai'
        />
      </div>
    </Layout>

)

export default VandensParuosimas